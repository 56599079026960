'use client';

import { createContext, useState } from 'react';
import type { PropsWithChildren } from 'react';

import { type Environment } from './environment';

export const EnvironmentContext = createContext<
  (Environment & { updateAuthToken: (token: string) => void }) | null
>(null);

export const EnvironmentProvider = ({
  environment,
  children,
}: PropsWithChildren<{
  environment: Environment;
}>) => {
  const [env, setEnv] = useState(environment);

  return (
    <EnvironmentContext.Provider
      value={{
        ...env,
        updateAuthToken: (token) => {
          setEnv({ ...env, authToken: `Bearer ${token}` });
        },
      }}
    >
      {children}
    </EnvironmentContext.Provider>
  );
};
